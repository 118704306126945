<template>
  <div class="row">

    <div class="col-md-12">

      <Card :title="'Kalender Penggunaan Ruangan'">
        <template v-slot:body>
          <b-tabs>
            <b-tab title="Berdasar Kalender">
              <CalendarMode />
            </b-tab>
            <b-tab title="Berdasar Ruangan">
              <DailyMode />
            </b-tab>
          </b-tabs>
        </template>
      </Card>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CalendarMode from '@/component/appointments/RoomUsageCalendarMode.vue'
import DailyMode from '@/component/appointments/RoomUsageDailyMode.vue'

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    DailyMode,
    CalendarMode,
    Treeselect
  },

  data: () => ({
  }),

  methods: {

  },

  watch: {
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penggunaan Ruangan", route: "" },
      { title: "Kalender" },
    ])
  },

}
</script>