import api from '@/core/services/api.service.js'
api.setHeader()

const AppointmentModule = {

  async getTimeSlots(date, weekday, doctorId) {
    let arr = []
    let response = await api.get('time-slots/get-by-date', `?date=${date}&weekday=${weekday}&doctor=${doctorId}`)
    response.data.data.forEach(value => {
      arr.push({
        text: value.name,
        value: value.id,
        start_time: value.start_time,
        end_time: value.end_time
      })
    })
    arr.unshift({ text: 'Pilih Jadwal', value: '', disabled: true })
    arr.push({ text: 'Kustom', value: 'custom' })
    return arr
  },

  async getUnusedRooms(date, startTime, endTime, appointmentId) {
    let arr = []
    let queryParams = `?date=${date}&start_time=${startTime}&end_time=${endTime}`
    if (appointmentId != null)
      queryParams += `&appointment_id=${appointmentId}`
    let response = await api.get('rooms/get-unused-room', queryParams)
    response.data.data.forEach((value) => {
      arr.push({ label: value.name, id: value.id })
    })
    arr.unshift({ label: 'Pilih Ruangan', id: '', isDisabled: true })
    return arr
  },

  async getRooms() {
    let arr = []
    let response = await api.get('rooms')
    response.data.data.forEach((value) => {
      arr.push({ label: value.name, id: value.id })
    })
    arr.unshift({ label: 'Pilih Ruangan', id: '', isDisabled: true })
    return arr
  },

  async getAppointmentStatuses() {
    let obj = { treeselect: [], data: [] }
    let response = await api.get('appointment-statuses')
    if (response.status == 200) {
      response.data.data.forEach((value) => {
        obj.treeselect.push({ label: value.name, id: value.id })
        obj.data.push(value)
      })
      return obj
    }
    return null
  },

  async getSimpleAppointmentStatuses() {
    let arr = []
    let response = await api.get('appointment-statuses')
    if (response.status == 200) {
      arr = response.data.data
      return arr
    }
    return null
  }

}

export default AppointmentModule